.plant-list {
    /* display: flex; */
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px;
}


.plant-item {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 13px;
    border-radius: 8px;
    padding: 10px 5px;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.plant-item img {
    width: 30px;
    height: 30px;
    margin-bottom: 10px;
}

.plant-item .title {
    margin-bottom: 2px;
    font-size: 14px;
    color: #000;
    font-weight: 500;
}

.plant-item .value {
    font-size: 25px;
    font-weight: 700;
}

.plant-item.total-capacity {
    background-color: #FFF4E6;
    border-color: #FFD9A6;
    color: #F57C00;
}

.plant-item.generation {
    background-color: #E6F7FF;
    border-color: #A6E1FF;
    color: #007ACC;
}

.plant-item.co2-emission {
    background-color: #E6FBE6;
    border-color: #A6F2A6;
    color: #4CAF50;
}

.plant-item.cost-savings {
    background-color: #F9F0FF;
    border-color: #E1BEE7;
    color: #AB47BC;
}

.report-title {
    display: flex
;
    justify-content: space-between;
    align-items: center;
    margin: 10px 15px 10px 15px;
    padding-left: 10px;
    position: relative;
    font-weight: 700;
    padding: 3px 14px;
    font-size: 19px;
    border-radius: 13px;
    text-transform: uppercase;
    background-image: linear-gradient(to right top, #d4d4d4, #dfdfdf, #e9e9e9, #f4f4f4, #ffffff);
}
.daily-title{
    background-image: linear-gradient(to right top, #d4d4d4, #dfdfdf, #e9e9e9, #f4f4f4, #ffffff);
    padding: 5px 16px;
    border-radius: 14px;
    font-size: 19px;
    
}
.powered-by {
    color: red; /* Purple color for "hai®" */
    font-style: italic; /* Optional for emphasis */
    margin-left: 4px;
}
.small-reg {
    font-size: 10px; /* Reduce size of the ® symbol */
    vertical-align: super; /* Position it as a superscript */
    line-height: 0;
}
.select-plant-btn{
    background-color: #ffffff !important;
    color: black !important;
    border: 1px solid black !important;
}
.report-title-span {
    margin-left: 10px;
    cursor: pointer;
    position: relative;
    color: #2c3e50;
    /* Adjust icon color */
    font-size: 14px;
    /* Optional: Adjust icon size */
    transition: color 0.3s;
    /* Optional: Add a hover effect */
}

.report-title-span:hover {
    color: #007ACC;
}

.toggle-info {
    position: absolute;
    right: 74%;
    width: 250px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    text-align: center;
}

.toggle-info-popup {
    position: absolute;
    right: 3%;
    width: 250px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    text-align: center;
}


.report-text {
    font-size: 14px;
    padding-left: 10px;
}

.daily-generation {
    background-color: #fff;
    margin-top: 10px;
    border-radius: 8px;
    padding-bottom: 10px;
    height: 350px;
}

.daily-generation-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
   
}
.insight-box .slick-dots {
    display: flex !important;
    justify-content: center;
  }
  
  .custom-dot {
    width: 10px;
    height: 10px;
    background: gray;
    border-radius: 50%;
    margin: 15px 5px;
    cursor: pointer;
  }
  
  .insight-box .slick-active .custom-dot {
    background: black; /* Active dot color */
  }
.insight-box .slick-dots {
    bottom: 60px;
    width: auto;
    left: 163px;
    margin: 0;
    list-style: none;
    text-align: center;
    top: 88%;
}
.insight-box .slick-dots li button:before {
    font-size: 14px;
    opacity: .50;
    color: gray;
}
.insight-text {
    background-color: #d5ebf7;
    height: 100px;
    text-align: center;
    margin: 10px;
    padding: 10px;
    display: flex !important;
    align-items: center;
    font-size: 16px;
    justify-content: center;
    border-radius: 12px;
    width: 94% !important;
   overflow: auto;
}

.performance-plant-list {
    display: flex;
    justify-content: flex-start; /* Align items to the start */
    padding: 10px 5px;
    overflow-x: auto;
    overflow-y: hidden; /* Prevent vertical scrolling */
    width: 93%; /* Full-width container */
    gap: 10px;
    height:68px;
    margin: 15px 10px 0px 10px;
    height: 68px;
    /* Hide the default scrollbar for WebKit browsers */
 
    -ms-overflow-style: none; /* IE */
}

.performance-plant-list::-webkit-scrollbar {
    height: 6px; /* Adjust scrollbar height */
}

.performance-plant-list::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2); /* Custom scrollbar thumb color */
    border-radius: 4px;
}

.performance-plant-list::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1); /* Custom scrollbar track color */
}

.performance-plant-item {
    border-radius: 8px;
    padding: 5px 5px;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.1);
    flex-shrink: 0; /* Prevent items from shrinking */
    min-width: 120px; /* Minimum width for consistent scrolling */
    margin-right: 11px;
}

.plant-name {
    font-size: 12px;
    font-weight: bold;
    word-break: break-word; /* Allow wrapping */
    white-space: normal; /* Ensure no truncation */
    color: white;
}

.plant-value {
    font-size: 12px;
    word-break: break-word; /* Allow wrapping */
    color: white;
}
